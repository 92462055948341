import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

const Privacypolicy = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="Privacy-policy">
        <div className="Policy-herosection">
          <div className="Policy-top">
            <div className="Policy-inside">
              <h3 className="Policy-title">
                The First Class App Privacy Policy
              </h3>
            </div>
          </div>
        </div>
        <div className="Policy-data-below">
          <div className="container">
            <div className="Policy-box-inside">
              <p className="Policy-subtext">
                At The First Class, ensuring the privacy and security of our
                Members' personal information is our top priority. This Privacy
                Policy outlines in detail how we collect, use, disclose, and
                protect the data gathered through our website, mobile
                application, and related services (collectively referred to as
                the "App"). We strongly encourage Members to carefully review
                this Privacy Policy to gain a comprehensive understanding of how
                their information is handled. The First Class App ("App")
                provides an online platform connecting influential individuals
                such as influencers, celebrities, models, and other
                personalities ("Members") with brands, venues, businesses,
                companies, and other entities ("Businesses") that offer various
                promotions, events, and other promotional opportunities
                ("Promotions").
              </p>
              <h2 className="Privacy-text1">1.0 Information We Collect</h2>
              <p className="privacy-text2">
                1.1 Account and Profile Information from Influencers <br />
                <span className="text3-edit ">
                  When Members create an account on the First Class App, we
                  collect various essential details to facilitate their
                  experience and interactions within the App ecosystem. This
                  includes, but is not limited to, the following information:
                </span>
              </p>
              <li className="privacy-list-text">
                {" "}
                Name: Members' full name for identification purposes.
              </li>
              <li className="privacy-list-text">
                Email address: A valid email address for account communication
                and verification.{" "}
              </li>
              <li className="privacy-list-text">
                Phone number: Mobile or landline number for additional contact
                and verification purposes.{" "}
              </li>
              <li className="privacy-list-text">
                Location: Members' current location or city of residence, which
                aids in personalizing event recommendations.
              </li>
              <li className="privacy-list-text">
                Gender: Information about the user's gender identity.
              </li>
              <li className="privacy-list-text">
                Age / Date of Birth: To ensure compliance with age-related
                policies and to provide relevant content.
              </li>

              <li className="privacy-list-text">
                Device IDs: Unique identifiers associated with the devices used
                to access the App, enabling seamless user experiences across
                multiple devices.
              </li>
              <li className="privacy-list-text">
                Instagram account information: Details about the user's
                Instagram account, which is essential for influencer
                verification and event matching.
              </li>
              <li className="privacy-list-text">
                Additional profile information: Any other details Members choose
                to provide in their account profiles to enhance their experience
                on the App.
              </li>

              <li className="privacy-list-text">
                Nationality: Information about Members Nationality
              </li>

              <p className="privacy-text2">
                1.2 Instagram Data
                <br />
                <span className="text3-edit ">
                  With explicit consent from influencers, we access specific
                  data from their Instagram accounts via the Instagram Official
                  API. This data is crucial for verifying influencer
                  credentials, analysing their reach and influence, and ensuring
                  relevant event matches. The accessed data includes, but is not
                  limited to:
                </span>
              </p>
              <li className="privacy-list-text">
                {" "}
                Profile information: Basic details about the influencer's
                Instagram profile.
              </li>
              <li className="privacy-list-text">
                {" "}
                Follower count: The number of followers an influencer has, which
                is a key metric for determining their influence.
              </li>
              <li className="privacy-list-text">
                {" "}
                Engagement metrics: Data on the interactions (likes, comments,
                shares) an influencer's content receives, indicating their level
                of engagement with their audience.
              </li>
              <li className="privacy-list-text">
                Reach: The potential audience size an influencer can impact
                through their content.
              </li>
              <li className="privacy-list-text">
                Audience demographics: Information about the age, gender, and
                other characteristics of an influencer's followers, helping to
                match them with relevant events and brands.
              </li>
              <li className="privacy-list-text">
                Audience interests: Insights into the interests and topics that
                resonate with an influencer's audience, enabling more targeted
                event recommendations.
              </li>

              <li className="privacy-list-text">
                {" "}
                Interaction numbers: Quantitative data on the interactions
                (likes, comments, shares) an influencer's content receives,
                providing insights into their engagement levels.
              </li>
              <li className="privacy-list-text">
                Advertiser IG page: Information about any Instagram pages an
                influencer may have dedicated to advertising or sponsored
                content.
              </li>
              <li className="privacy-list-text">
                Visibility on stories/posts during events: Access to the stories
                and posts shared by influencers during events they attend
                through the First Class App, enabling tracking and analysis of
                their promotional efforts.
              </li>

              <p className="Policy-subtext">
                It's important to note that we only access data explicitly made
                available through Instagram's Business Account integration,
                ensuring compliance with their policies and guidelines.
              </p>
              <p className="privacy-text2">
                1.3 FC Verification Data
                <br />
                <span className="text3-edit ">
                  To ensure the authenticity and credibility of influencers on
                  the First Class App, we require them to undergo a verification
                  process. This process involves providing the following
                  information:
                </span>
              </p>
              <p className="privacy-text2">Media content:</p>
              <li className="privacy-list-text">
                Selfie images: Influencers must submit a current selfie image to
                verify their identity.
              </li>
              <li className="privacy-list-text">
                10-second videos: A short video clip is requested to confirm the
                influencer's appearance and identity.
              </li>
              <li className="privacy-list-text">
                Social media profile usernames: Influencers must provide the
                usernames associated with their social media profiles for
                verification purposes.
              </li>

              <p className="privacy-text2">
                Business information (for business accounts):
              </p>
              <li className="privacy-list-text">
                Business Name: The official name of the business entity, if
                applicable.
              </li>
              <li className="privacy-list-text">
                Business ID: Identification documents or registration numbers
                associated with the business.
              </li>
              <li className="privacy-list-text">
                Business Utility Bill: A recent utility bill or other proof of
                business address to verify the legitimacy of the business.
              </li>
              <li className="privacy-list-text">
                {" "}
                Location: The physical location or address of the business and
                upload photos of the business.
              </li>
              <li className="privacy-list-text">
                Phone Number & Address: Businesses must provide the contact
                information, including phone numbers, Email and addresses.
              </li>
              <li className="privacy-list-text">
                {" "}
                Social media profile usernames: Businesses must provide the
                usernames associated with their social media profiles for
                verification purposes.
              </li>
              <li className="privacy-list-text">
                This verification process ensures that only genuine and
                qualified influencers can access and participate in events
                through the First Class App, maintaining the integrity of the
                platform.
              </li>
              <p className="privacy-text2">1.4 Usage Data</p>

              <li className="privacy-list-text">
                Activity: Data on the actions and interactions Members take
                within the App, such as searching for events, bookmarking
                venues, or engaging with other Members.
              </li>
              <li className="privacy-list-text">
                Event participation: Records of the events Members have
                registered for or attended through the App.
              </li>
              <li className="privacy-list-text">
                Venues visited: Information about the venues Members have
                visited or interacted with through the App.
              </li>
              <li className="privacy-list-text">
                Reviews provided by venues to influencers: Feedback and ratings
                provided by venues to influencers based on their performance and
                promotional efforts during events.
              </li>

              <p className="privacy-text2">1.5 Device Information</p>
              <p className="privacy-smalltext">
                To ensure a seamless and optimized user experience, we may
                gather data related to the devices used to access the First
                Class App. This information includes, but is not limited to:
              </p>
              <li className="privacy-list-text">
                Device type: The specific model of the device used, such as a
                smartphone, tablet, or laptop.
              </li>
              <li className="privacy-list-text">
                Operating system: The operating system and version running on
                the device, such as iOS or Android.
              </li>

              <li className="privacy-list-text">
                {" "}
                Unique device identifiers: Identifiers associated with the
                device, which help us recognize and personalize the user
                experience across multiple devices.
              </li>
              <li className="privacy-list-text">
                IP address: The Internet Protocol (IP) address assigned to the
                device, which can provide location-based services and insights.
              </li>
              <h2 className="Privacy-text1"> 2. How We Use Your Information</h2>

              <p className="privacy-smalltext">
                At The First Class, we utilize the collected data to enhance and
                personalize the user experience, facilitate seamless
                communication, analyze performance, and ensure compliance with
                legal and policy requirements. Specifically, the information is
                used for the following purposes:
              </p>
              <li className="privacy-list-text">
                Improving and personalizing the First Class App: By analyzing
                user preferences, behavior, and data, we can tailor the App
                experience to better suit individual needs. This includes
                providing relevant event and venue recommendations based on
                Members' interests, locations, and influencer profiles.
              </li>

              <li className="privacy-list-text">
                Facilitating communication: The collected data enables us to
                communicate effectively with Members, providing updates on
                account activities, registered events, and addressing customer
                support inquiries promptly.
              </li>
              <li className="privacy-list-text">
                Analyzing usage patterns: By studying how Members interact with
                the App, we can identify areas for improvement and enhance the
                overall functionality and user experience.
              </li>
              <li className="privacy-list-text">
                Creating insights and analytics: User data is utilized to
                generate valuable insights and analytics for both influencers
                and businesses using the App. This includes performance metrics,
                audience demographics, and engagement levels, which can inform
                future strategies and decision-making.
              </li>

              <li className="privacy-list-text">
                Analyzing promotional post performance: When influencers attend
                events through the First Class App and promote them on their
                Instagram platforms, we analyze the performance of their
                promotional posts, including reach, engagement, and visibility.
                This data helps us optimize event matchmaking and evaluate the
                success of partnerships.
              </li>
              <li className="privacy-list-text">
                Adhering to legal requirements and enforcing policies: User
                information is essential for ensuring compliance with applicable
                laws, regulations, and the App's policies, maintaining the
                integrity and security of the ecosystem.
              </li>
              <h2 className="Privacy-text1">
                3. Sharing and Disclosure of Information
              </h2>

              <p className="privacy-smalltext">
                At The First Class, we prioritize the privacy and
                confidentiality of user information. However, we may share user
                data under the following legitimate circumstances:
              </p>
              <li className="privacy-list-text">
                With venues, event organizers, and brands: To facilitate
                relevant connections and experiences, we may share user
                information with venues, event organizers, and brands that are
                matched with Members through the App. This sharing is limited to
                the information necessary for coordinating and executing
                successful events and partnerships.
              </li>

              <li className="privacy-list-text">
                With trusted service providers: We may engage trusted
                third-party service providers to assist in the operation and
                delivery of the App's services. In such cases, these providers
                are bound by strict confidentiality agreements and are only
                granted access to user information necessary for performing
                their contracted tasks.
              </li>

              <li className="privacy-list-text">
                In the event of a merger, acquisition, or corporate
                restructuring: If The First Class undergoes a merger,
                acquisition, or corporate restructuring, user data may be
                transferred as part of the transaction. In such cases, we will
                take reasonable steps to ensure the continued protection and
                proper handling of user information.
              </li>

              <li className="privacy-list-text">
                {" "}
                To comply with legal obligations: We may disclose user
                information if required by law, court order, or governmental
                request, to comply with legal obligations and protect the
                rights, property, or safety of The First Class, its Members, or
                the public.
              </li>
              <li className="privacy-list-text">
                To protect rights and safety: In cases where it is necessary to
                protect the rights, property, or safety of The First Class, its
                Members, or the public, we may share user information as
                permitted by applicable laws.
              </li>
              <h2 className="Privacy-text1">4. Your Choices and Rights</h2>
              <p className="privacy-smalltext">
                At The First Class, we believe in empowering our Members with
                choices and rights regarding their personal information. Members
                have the following options:
              </p>
              <li className="privacy-list-text">
                Accessing, updating, or deleting account information: Members
                can access, update, or delete their account information through
                the account settings within the App. This ensures that their
                data remains accurate and relevant to their preferences.
              </li>
              <li className="privacy-list-text">
                {" "}
                Disconnecting Instagram accounts: Members have the ability to
                disconnect their Instagram accounts from the First Class App at
                any time, providing them with control over the data sharing
                preferences related to their social media profiles.
              </li>
              <li className="privacy-list-text">
                Opting out of promotional communications: Members can choose to
                opt-out of receiving promotional emails, push notifications, or
                other marketing communications from The First Class by adjusting
                their preferences within the App's settings or by following the
                unsubscribe instructions provided in such communications.
              </li>
              <li className="privacy-list-text">
                Exercising GDPR rights for EEA residents: For Members residing
                within the European Economic Area (EEA), additional rights are
                granted under the General Data Protection Regulation (GDPR).
                These include the right to data portability, which allows
                Members to obtain a copy of their personal data in a structured,
                commonly used, and machine-readable format, as well as the right
                to erasure, which enables Members to request the deletion of
                their personal data under certain circumstances.
              </li>
              <h2 className="Privacy-text1">5. Data Security</h2>
              <p className="privacy-smalltext">
                At The First Class, we understand the importance of safeguarding
                user data and have implemented robust security measures to
                protect it from unauthorized access, use, or disclosure. These
                measures include, but are not limited to:
              </p>
              <li className="privacy-list-text">
                Technical security measures: We employ industry-standard
                encryption protocols, firewalls, and other technical safeguards
                to secure user data during transmission and storage.
              </li>
              <li className="privacy-list-text">
                Administrative security measures: We have established strict
                internal policies, procedures, and access controls to limit
                access to user data only to authorized personnel on a
                need-to-know basis.
              </li>
              <li className="privacy-list-text">
                Physical security measures: Our data centres and facilities are
                protected by advanced physical security measures, including
                access controls, surveillance systems, and other precautions.
              </li>
              <li className="privacy-list-text">
                However, it's important to note that no system or method of data
                transmission over the internet or electronic storage is entirely
                secure or error-free. While we strive to maintain the highest
                standards of security, Members should exercise caution and take
                necessary precautions to protect their personal information.
              </li>
              <h2 className="Privacy-text1">
                6. Third-Party Links and Integration
              </h2>
              <p className="privacy-smalltext">
                The First Class App may include links to third-party websites,
                applications, or services that are not owned or controlled by
                The First Class. Additionally, the App may integrate with
                third-party platforms like Instagram to facilitate certain
                functionalities. Members should be aware that these third
                parties have their own privacy policies and practices, which may
                differ from those of The First Class. We encourage Members to
                review the privacy policies of any third-party services they
                interact with through the App.
              </p>
              <h2 className="Privacy-text1">7. Children's Privacy</h2>
              <p className="privacy-smalltext">
                The First Class App is intended for use by individuals aged 16
                and above. We do not knowingly collect personal information from
                children under the age of 16. If we become aware that we have
                inadvertently collected personal information from a child under
                16 without parental consent, we will take reasonable steps to
                delete such information from our systems as soon as possible.
              </p>
              <p className="privacy-smalltext">
                Parents or guardians who believe their child may have provided
                personal information to The First Class should contact us at
                info@the firstclassapp.club to request the removal of such
                information.
              </p>
              <h2 className="Privacy-text1">
                8. Changes to this Privacy Policy
              </h2>
              <p className="privacy-smalltext">
                As our services evolve and privacy laws and regulations change,
                we may need to update this Privacy Policy from time to time. Any
                changes or updates to this Privacy Policy will be communicated
                through the App or other appropriate channels, and the updated
                version will be made available within the App.
              </p>
              <p className="privacy-smalltext">
                Members are encouraged to review this Privacy Policy
                periodically to stay informed about our data practices. By
                continuing to use the First Class App after any changes or
                updates to this Privacy Policy, Members are acknowledging their
                acceptance of the revised terms.
              </p>
              <h2 className="Privacy-text1">9. Contact Us</h2>
              <p className="privacy-smalltext">
                If you have any questions, concerns, or inquiries regarding this
                Privacy Policy or our data practices, please don't hesitate to
                contact us at <NavLink to="mailto:info@the-firstclass.club">info@the-firstclassapp.club</NavLink> We are committed to
                addressing user queries and concerns promptly and transparently.
                By using The First Class App, Members consent to the data
                practices outlined in this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacypolicy;
