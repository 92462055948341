import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ContactUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://contactapi.the-firstclass.club/contact-us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
   
        toastr.success("Successfully submitted. Our support team will connect with you")
        reset();
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("An error occurred while submitting the form", error);
    }
  };


  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <div className="contactus-page">
        <div className="contactus-herosection">
          <h3 className="contactus-title">Contact us</h3>
        </div>
        <div className="get-connected-section">
          <div className="connected-inside">
            <div className="row">
              <div className="col-md-6">
                <div className="get-connected-left">
                  <h2 className="contact-left-title">
                  {/* Experience unparalleled brand elevation and influencer engagement with the First Class Club */}
                  Elevate Your Brand and Engage Influencers with The First Class
                  </h2>
                  <p className="cntactus-txt-data">
                  
                     Gain access to exclusive culinary delights, VIP event privileges, luxurious journeys, and early product experiences. For influencers, it’s a gateway to creating impactful content and accessing elite experiences at no cost. Businesses benefit from targeted campaigns, data-driven insights, and organic marketing opportunities. Join us and redefine your brand’s influence with the First Class.
                  </p>
                  
                  <p className="your-input-data">
                  First Class App is available in vibrant locations such as Dubai, Miami, Mykonos, London, Mexico City, Tulum, Cancun, San Diego, Los Angeles, Tijuana and more
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="get-connected-right">
                  <h2 className="connected-title">Get Connected</h2>
                  <form
                    className="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* <div className="form-group formgrp-top">
                      <label
                        className="form-lebels lebel-textss"
                        htmlFor="whoareyou"
                      >
                        Who are you?*
                      </label>
                      <div className="position-relative">
                        <select
                          className="form-control finputText "
                          id="whoareyou"
                          name="whoareyou"
                          {...register("whoareyou", { required: true })}
                        >
                          <option value="">Select who are you</option>
                          <option value="Guest">Guest</option>
                          <option value="Staycation">Staycation</option>
                          <option value="Restaurant">Restaurant</option>
                          <option value="Night clubs">Night clubs</option>
                        </select>
                        {errors.whoareyou && (
                          <span className="error-msg">
                            This field is required
                          </span>
                        )}

                        <img
                          className="down-arow-select"
                          src="/images/contactus/yelow-down-arw.png"
                        />
                      </div>
                    </div> */}
                    <div className="form-group formgrp-top">
                      <label
                        htmlFor="Yourname"
                        className="form-lebels lebel-textss"
                      >
                        Your name*
                      </label>
                      <input
                        type="text"
                        className="form-control finputText"
                        id="Yourname"
                        placeholder="Your name"
                        {...register("yourname", { required: true })}
                      />
                      {errors.yourname && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="form-group formgrp-top">
                      <label
                        htmlFor="emailid"
                        className="form-lebels lebel-textss"
                      >
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control finputText"
                        id="emailid"
                        placeholder="Email"
                        {...register("email", { required: true })}
                      />
                      {errors.email && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="form-group formgrp-top">
                      <label
                        htmlFor="phoneNumber"
                        className="form-lebels lebel-textss"
                      >
                        Phone*
                      </label>
                      <input
                        type="tel"
                        className="form-control finputText"
                        id="phoneNumber"
                        placeholder="Phone"
                        {...register("phone", {
                          required: true,
                          pattern: /^[0-9]*$/,
                        })}
                      />
                      {errors.phone && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div className="form-group formgrp-top">
                      <label
                        htmlFor="Description-text"
                        className="lebel-textss"
                      >
                        Description*
                      </label>
                      <input
                        type="text"
                        className="form-control finputText textbox"
                        id="Description-text"
                        placeholder="Leave a Description"
                        {...register("description", { required: true })}
                      />
                      {errors.description && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </div>
                    <button type="submit" className="contact-formBtn">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
