import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'


const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <>
      <div className="footer-content">
        <div className="container">
          <div className="follow-us">
            <ul className="footer-socialmedia">
              <li className="footer-lists">
                <NavLink
                  className="left-socialmedia"
                  to="https://www.instagram.com/thefirstclassclub "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="socialmedia-icon"
                    src="/images/footer/instagram.png"
                    alt="no-data"
                  />
                  <span className="followUs-text">Follow us on Instagram</span>
                </NavLink>
                {/* <div className=""></div> */}
              </li>
              <li className="footer-lists">
                <div className="playstore-data">
                  <NavLink className="appstore-top" onClick={openModal} >
                    <img src="/images/footer/android.png" className="appstore-icon" />
                  </NavLink>
                  <NavLink className="appstore-top" onClick={openModal}>
                    <img src="/images/footer/applestore.png" className="appstore-icon" />
                  </NavLink>
                </div>
              </li>
            </ul>

            <ul className="terms-us">
              <li className="">
                <p className="copy-rights">
                  First Class © 2024 - All rights reserved
                </p>
              </li>
              <li className="">
                <ul className="policy-list">
                  <li className="policy-data">
                  <NavLink className="privacy-btns" to="/privacy-policy" >Privacy Policy</NavLink>
                    {/* <NavLink className="privacy-btns" to="/files/privacy-policy.pdf" target='_blank'>Privacy Policy</NavLink> */}
                  </li>
                  <li className="policy-data">
                    {/* <NavLink className="privacy-btns" to="/files/terms-and-conditions.pdf" target='_blank'>Terms of Use</NavLink> */}
                    <NavLink className="privacy-btns" to="/terms-and-conditions" >Terms of Use</NavLink>
                  </li>
                  <li className="policy-data">
                    <NavLink className="privacy-btns" to="mailto:info@the-firstclass.club">Contact Us</NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-dialog ">
            <div className="modal-content coming-modals">
              <div className="modal-header coming-header">
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body modal-body-data">
                <p className='modal-paras'>We’re excited to announce that our application will be launching in the first week of July. Stay tuned for updates and get ready to download it from the Play Store and App Store soon!</p>
              </div>

            </div>
          </div>
        </div>
      )}

      {showModal && <div className="modal-backdrop" onClick={closeModal}></div>}


    </>
  )
}

export default Footer