
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [navMenuActive, setNavMenuActive] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleHamburgerClick = () => {
    setNavMenuActive(!navMenuActive);
  };

  // Load initial theme preference from localStorage
  useEffect(() => {
    const storedTheme = localStorage.getItem("darkMode");
    setIsDarkMode(storedTheme ? JSON.parse(storedTheme) : false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setNavMenuActive(false);
  };

  return (
    <>
      <div className="main-navbar">
        <header>
          
          <nav
            className={`navbar navbar-expand-lg navbar-light TopnavBar-Section p-2 ${
              scrolled ? "scrolled" : ""
            }`}
          >
            <div id="progress-barss"></div>
            <div className="container">
              <NavLink className="navbar-brand logo-section" to="/">
                <img
                  className="main-logo"
                  src="/images/navbar/first-logo-2.svg"
                  loading="eager"
                  alt="Firstclass logo"
                />
              </NavLink>
              {/* <form className="form-inline d-block d-lg-none">
                <a className="btn login-txt" type="button" href="mailto:info@the-firstclass.club">
                  Support
                </a>
              </form> */}
              <div
                className={`hamburger-edit ${navMenuActive ? "active" : ""}`}
                onClick={handleHamburgerClick}
              >
                <span className="bar" />
                <span className="bar" />
                <span className="bar" />
              </div>
              <div
                className={`navbar-collapse navbarTop-colapse ${
                  navMenuActive ? "active" : ""
                }`}
              >
                <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">
                  <li className="nav-item">
                    <NavLink   className="nav-link navlink-mainlist "  to={"/home"} onClick={closeMenu} >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link navlink-mainlist" to={"/guests"} onClick={closeMenu} >
                      Guests
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link navlink-mainlist" to={"/venues"} onClick={closeMenu} >
                      Venue
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink className="nav-link navlink-mainlist" to={"/how-to-switch"} onClick={closeMenu}>
                      How to Switch?
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink className="nav-link navlink-mainlist" to="mailto:info@the-firstclass.club" onClick={closeMenu} >
                      Support
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link navlink-mainlist" to={"/contact-us"} onClick={closeMenu} >
                      Contact us
                    </NavLink>
                  </li>
                </ul>
                {/* <form className="form-inline d-none d-lg-block">
                  <a className="btn login-txt" type="button" href="mailto:info@the-firstclass.club">
                    Support
                  </a>
                </form> */}
              </div>
            </div>
          </nav>
          
        </header>
      </div>
    </>
  );
};

export default Header;

