import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

const Landing = () => {

  const videoRefDesktop = useRef(null);
  const videoRefMobile = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 800,  // Adjust duration for quicker animations on mobile
      easing: 'ease-in-out',
      mirror: true,   // Ensure animations happen when scrolling back up
      offset: 50,
      once: false,
    });

    // Optional: Refresh AOS to detect newly added elements
    AOS.refresh();
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const videoDesktop = videoRefDesktop.current;
    const videoMobile = videoRefMobile.current;

    const handlePlayPause = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.play().then(() => {
            console.log('Autoplay started successfully');
          }).catch(error => {
            console.log('Autoplay failed:', error);
          });
        } else {
          entry.target.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayPause, { threshold: 0.5 });

    if (videoDesktop) observer.observe(videoDesktop);
    if (videoMobile) observer.observe(videoMobile);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>
        First Class Club: Where Exclusive Experiences Meet Brand Influence
        </title>
        <link rel="canonical" href="https://the-firstclass.club/" />
      </Helmet>
      <div className='landing-content'>
        <div className="video-container">
          <div className='overlay-vdo'></div>
          <video className=' d-none  d-md-block'  id="background-video-desktop" ref={videoRefDesktop} autoPlay loop muted playsInline>
            <source src="/video/HomePg-video.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <video className='  d-block d-md-none' id="background-video-mobile" ref={videoRefMobile} autoPlay loop muted playsInline>
            <source src="/video/videosmallbg.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <div className='content-data'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-9'>
                  <div className='hero-section'>
                    <h1 className="herosection-title infinite" data-aos="fade-down">
                      Elevate Your Influence
                    </h1>
                    <h1 className="herosection-beyond infinite" data-aos="fade-down" >
                      Beyond Visibility - <br /> A Realm of Exclusivity <br /> Awaits
                    </h1>
                    <p className="herosection-para infinite" data-aos="fade-down">
                      You're not just building a following; you're leading a movement. At First Class, we transcend the ordinary, offering you a passport to experiences that redefine luxury and exclusivity.
                    </p>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

        {/* ------------------Elevate Your Brand with First Class */}
        <div className="connect-influence">
          <div className="container">
            <h1 className="elevatebrand-title" data-aos="fade-up"
            >Elevate Your Brand with First Class</h1>
            <h1 className="Connect-title" data-aos="fade-up">Connect, Influence, Succeed</h1>
            <p className="unlock-para" data-aos="fade-up"
            >
              Unlock a world of influencer partnerships with First Class. Our platform
              streamlines your path to meaningful engagement, pairing your brand with
              top-tier influencers to drive growth and foster authentic connections.
            </p>
            <div className="data-list">

              <div className='row'>
                <div className='col-md-6'>
                  <div className='left-data'>
                    <div className='influence-image'>
                      <img className="image-inside-data" src="/images/landing/influence.webp" alt="First class App Analytics Dashboard for Influencers " />

                    </div>

                  </div>

                </div>
                <div className='col-md-6'>
                  <div className='right-data'>
                    <ul className='influence-list'>
                      <li className='influence-list-inside'><span className='influence-span'>01</span><p className='influence-para'>Access an exclusive network of influencers</p></li>
                      <li className='influence-list-inside'><span className='influence-span'>02</span><p className='influence-para'>Benefit from strategic influencer-brand matchmaking</p></li>
                      <li className='influence-list-inside'><span className='influence-span'>03</span><p className='influence-para'>Enjoy comprehensive campaign management
                      </p></li>
                      <li className='influence-list-inside'><span className='influence-span'>04</span><p className='influence-para'>Utilize cutting-edge analytics for measurable success</p></li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <img className="text-image" src="/images/landing/text-img.webp" alt="influence-data" />
          <img className="shadow-image" src="/images/landing/shadow.webp" alt="influence-data" />

          {/* companylist */}
        </div>
        <div className='video-content'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-6'>
                <div className='left-content'>
                  <div className='left-content-inside'>
                  
                    <h3 className='left-subtitle'>Join forces with First Class and redefine your brand's presence in the digital marketplace.</h3>

                  </div>
                </div>
              </div>
              <div className='col-md-6'>
           
                <div className='right-content'>
                  <div className='videocontent-right-inside'>
                    <img className='image-data' src='/images/landing/video-image.webp' alt='First class Club FCC App Homepage, First Class App' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =================A Community Like No Other =========== */}
        <div className="community-content">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-6 col-xlg-5">
                <div className="best-meet" data-aos="zoom-in-down" >
                  <h1 className="community-head">A Community Like No Other</h1>
                  <h1 className="meet-bright">Where the Best Meet the Brightest</h1>
                  <p className="First-class">
                    First Class is more than a platform; it's a confluence of the best
                    and the brightest, a melting pot of talent, ambition, and success.
                    Join an elite community of influencers and creators who are
                    rewriting the rules, setting new trends, and leading the digital
                    age. Network, collaborate, and grow together in an environment
                    that's built on support, respect, and mutual success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ================== Elevate, Innovate, and Inspire with First Class ============= */}
        <div className="elevate-future">
        <img className='shadow2-image' src="/images/landing/shadoww2.webp"/>
          <div className="container">
            <div className="row h-100 d-flex justify-content-between">
              <div className="col-md-6">
                <div className='left-content'>
                  <div className="elevate-content">
                    <h1 className="elevate-head" data-aos="fade-right">
                      Elevate, Innovate, and Inspire with First Class
                    </h1>
                    <h1 className="future-head" data-aos="fade-right">
                      The Future is Here, and You're Invited
                    </h1>
                    <p className="journey-para" data-aos="fade-right">
                      The journey to the top is fraught with challenges, but you're not
                      alone. First Class is your partner, your ally, your mentor in this
                      journey. With cutting-edge tools, personalized support, and
                      unparalleled opportunities, we're here to ensure your path to
                      success is as smooth and rewarding as possible. The future of
                      digital influence and creativity is being written right now, and
                      at First Class, you hold the pen.
                    </p>
                    <p className="embark-para" data-aos="fade-right">
                      Are you ready to embark on this journey? Are you prepared to
                      redefine your destiny and leave your mark on the digital world?
                      Welcome to First Class—where your next chapter begins.
                    </p>
                  </div>

                </div>

              </div>

              <div className="col-md-6 col-lg-5 d-flex">
                <div className='right-image' data-aos="fade-down">
                  <img
                    className="elevate-image"
                    src="/images/landing/future1.webp"
                    alt='First Class Data Driven Influencer Marketing Platform For Venues'
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WhatWeDo-section">
          <div className="container-fluid">
            <h2
              className="WhatWeDo"
            >
              The future of digital influence with First Class
            </h2>
            <div className="row h-100">
              <div className="col-md-4 p-0">
                <div className="brand-collection">
                  <div className="image-container">
                    <img className="content-image" src="/images/landing/brand1.webp" alt="First Class Influencer Experiences In Dubai" />
                    <div className="content-overlay">
                      <div className="content-details fadeIn-bottom">
                        <h2 className="whatwedoh-title">Brand Collaborations</h2>
                        <p className="content-text">
                          Connect with brands that appreciate the power of creative storytelling. Our platform facilitates partnerships that resonate with your creative ethos, ensuring mutual growth and impact.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-0">
                <div className="brand-collection">
                  <div className="image-container">
                  {/* <img className="content-image" src="/images/landing/brand3.webp" alt="Resources for Growth" /> */}
                    <img className="content-image" src="/images/landing/braand3.png" alt="First Class Influencer Experiences In Mexico" />
                    <div className="content-overlay">
                      <div className="content-details fadeIn-bottom">
                        <h2 className="whatwedoh-title">Resources for Growth</h2>
                        <p className="content-text">
                          Access workshops, tools, and insights designed to refine your craft, expand your reach, and take your creative career to new heights.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-0">
                <div className="brand-collection">
                  <div className="image-container">
                    <img className="content-image" src="/images/landing/brand2.webp" alt="First Class Influencer Experiences In Miami" />
                    <div className="content-overlay">
                      <div className="content-details fadeIn-bottom">
                        <h2 className="whatwedoh-title">Supportive Community</h2>
                        <p className="content-text">
                          Join a community of like-minded creators who inspire and support each other. First Class is not just a platform; it's a collective where creativity thrives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

          </div>
        </div>

      </div>

    </>
  )
}

export default Landing