import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Venue = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Venues</title>
      </Helmet>
      <div className="venue-content">
        <div className="venue-herosection">
          <div className="vernu-inside">
            <h3 className="venue-title">
              Register <br />
              your Establishment
            </h3>
            <p className="venue-para">
              We create successful campaigns that are impactful, tangible, and
              result oriented. By crafting content around your members, we
              enhance your type of clientele and boost your venue's reputation
            </p>
            <Link className="venue-register-btn" to="/register">
              Register Now
            </Link>
          </div>
          <img src="../images/venue/venuebg.webp" alt="First Class App for venues and business establishments where can engage with influencers" class="venue-background-img"/>

        </div>
        <div className="boost-yourdata">
          <img
            className="shadow-image"
            src="/images/venue/layer1.png"
            alt="influence-data"
          />
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="boostrap-left">
                  <div className="boostrap-left-inside">
                    <img
                      src="/images/venue/boost22.png"
                      className="inside-image"
                      alt="First Class App For Boost Venue Reputation"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="boostrap-right">
                  <div className="boostrap-right-inside">
                    <h3 className="boostrap-title">
                      Boost Your Venue’s Reputation
                    </h3>
                    <p className="boostrap-para">
                      Enhance your venue’s profile by creating content that
                      showcases first-class service. With our strategies, your
                      venue will be synonymous with excellence and top-tier
                      experiences.
                    </p>
                    <div className="get-startBtn">
                      <Link className="boostrap-register-btn" to="/register">
                        Get Started Today
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="prapositions-content">
          <div className="container">
            <h3 className="prapo-main-title">Top Value Propositions</h3>
            <div className="row">
              <div className="col-md-4">
                <div className="praposition-card">
                  <div className="praposition-card-inside">
                    <h3 className="prapo-title">Reputation Management</h3>
                    <p className="prapo-para">
                      Enhance your venue’s profile with top-notch reviews and
                      strategic content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="praposition-card praposition-card1">
                  <div className="praposition-card-inside">
                    <h3 className="prapo-title">Audience Targeting</h3>
                    <p className="prapo-para">
                      Attract the right guests with tailored marketing
                      campaigns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="praposition-card praposition-card2">
                  <div className="praposition-card-inside">
                    <h3 className="prapo-title">Advanced Analytics</h3>
                    <p className="prapo-para">
                      Monitor performance and optimize future events with
                      detailed insights.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="create-experience">
          <div className="container">
            <h3 className="prapo-main-title">
              Create Unforgettable Experiences
            </h3>
            <div className="row">
              <div className="col-md-3">
                <div className="experience-content">
                  <p className="experience-para">
                    Transform your venue into the go-to destination for
                    memorable events. Whether it’s a party night or a Sunday
                    brunch, our selected premium tailored influencers ensure
                    your events stand out and attract the right audience.
                  </p>
                  {/* <div className='expert-Btn'>
                    <Link className='speakexpert-btn'>Speak to an Expert</Link>
                    </div> */}
                </div>
              </div>
              <div className="col-md-3">
                <div className="experience-image">
                  <img
                    src="/images/venue/exp22.png"
                    className="image-data"
                    alt="First Class App For Brands to Boost Awareness for Venues through Influencers"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="experience-image">
                  <img
                    src="/images/venue/exp-1.png"
                    className="image-data"
                    alt="First Class Club For Influencers "
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="experience-image">
                  <img
                    src="/images/venue/exp-3.png"
                    className="image-data"
                    alt="First Class Club For Business Establishment"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Venue;
