import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Guests = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800, // Adjust duration for quicker animations on mobile
      easing: "ease-in-out",
      mirror: true, // Ensure animations happen when scrolling back up
      offset: 50,
      once: false,
    });

    // Optional: Refresh AOS to detect newly added elements
    AOS.refresh();
  }, []);
  return (
    <>
      <Helmet>
        <title>guests</title>
      </Helmet>
      <div className="guests-page">
        <div className="guests-herosection">
          <div className="guests-inside">
            <h3 className="guests-title"  data-aos="fade-up">
              Experience the City Like Never Before
            </h3>
          </div>
          <img src="../images/Guests/guestbg.webp" alt="First Class Club for influencers where get the exclusive venues experiences through First Class App FCC" class="guest-background-img"/>
        </div>
        <div className="how-it-works">
          <div className="layer-arrow1">
            <img className="layer-image1" src="/images/Guests/layer1.png" />
          </div>
          <div className="layer-arrow2">
            <img className="layer-image2" src="/images/Guests/layer1.png" />
          </div>
          <div className="container">
            <h2 className="how-works-title">How It Works</h2>
            <img className="shadow-edit" src="/images/Guests/goldenshadow.png"/>
            <div className="row">
              <div className="col-md-4">
                <div className="card howIt-work-card">
                  <img
                    src="/images/Guests/how1.png"
                    alt="..."
                    className="card-img-top Howit-works-img"
                  />
                  <div className="card-body howit-works-body">
                    <h5 className="card-title download-subtitle">
                      Download the Application
                    </h5>
                    <p className="card-text download-text" >
                      Download our app and take the first step toward
                      experiencing the city in a way you've never imagined.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card howIt-work-card">
                  <img
                    src="/images/Guests/howw2.png"
                    alt="..."
                    className="card-img-top Howit-works-img"
                  />
                  <div className="card-body howit-works-body">
                    <h5 className="card-title download-subtitle">
                      Pass the Criteria
                    </h5>
                    <p className="card-text download-text">
                      Join an elite community of influencers. If you meet our
                      criteria, you’ll be granted access to the app’s exclusive
                      features.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card howIt-work-card">
                  <img
                    src="/images/Guests/howw3.png"
                    alt="..."
                    className="card-img-top Howit-works-img"
                  />
                  <div className="card-body howit-works-body">
                    <h5 className="card-title download-subtitle">
                      Enjoy First-Class Offers
                    </h5>
                    <p className="card-text download-text" >
                      Once approved, immerse yourself in exclusive parties,
                      unmatched promotions, and premium experiences. Savor the
                      city's finest offerings, curated just for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="influencer-regBtn">
          <Link className='influencer-register-btn' to="/influencer-registration">Register  Now</Link>
          </div>
        </div>
        <div className="firstclass-living">
          <img
            className="wave-layer1  d-md-block"
            src="/images/Guests/wave.webp"
          />
          <img
            className="color-layer1  d-md-block"
            src="/images/Guests/color1.webp"
          />
          <img
            className="wave-layer2  d-md-block"
            src="/images/Guests/wave2.webp"
          />
          <img
            className="color-layer2  d-md-block"
            src="/images/Guests/color2.png"
          />
          <img
            className="wave-layer1 wave-layer3  d-md-block"
            src="/images/Guests/wave.webp"
          />
          <img
            className="color-layer1 color-layer3  d-md-block"
            src="/images/Guests/color1.webp"
          />

          <div className="container">
            <h2 className="experiance-title">Experience First-Class Living</h2>
            <div className="row mb-2  mb-md-4  h-100 ">
              <div className="col-md-6">
                <div className=" experiance-body" data-aos="fade-right">
                  <h5 className="experiance-subtitle">
                    Luxury at Your Fingertips
                  </h5>
                  <p className="experiance-texts">
                    Indulge in a lifestyle where every detail is crafted to
                    perfection. From high-end dining to VIP event access, live
                    the life you deserve.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="experianceCard-image-top ">
                  <img
                    src="/images/Guests/expimg1.webp"
                    className="experiance-Image"
                    alt="First Class For Influencer Clientele"
                  />
                </div>
              </div>
            </div>
            <div className="row h-100 mb-2 flex-wrap-reverse  mb-md-4">
              <div className="col-md-6">
                <div className="experianceCard-image-top card2-top">
                  <img
                    className="experiance-Image"
                    src="/images/Guests/expimg2.webp"
                    alt="First Class Club For Exclusive Experiences In Dubai"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className=" experiance-body" >
                  <h5 className="experiance-subtitle">Exclusive Experiences</h5>
                  <p className="experiance-texts">
                    Be the first to know and attend the hottest events in the
                    city. Enjoy backstage passes, private viewings, and more,
                    all arranged for your ultimate pleasure.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-4  h-100">
              <div className="col-md-6">
                <div className=" experiance-body" data-aos="fade-right">
                  <h5 className="experiance-subtitle">Unmatched Offers</h5>
                  <p className="experiance-texts">
                    Immerse yourself in a world of elite events, unique
                    promotions, and first-class experiences. Live the life
                    you’ve always dreamed of, right in the heart of the city.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="experianceCard-image-top">
                  <img
                    src="/images/Guests/expimg3.webp"
                    className="experiance-Image"
                    alt="First Class Elite Events and Experiences In Dubai"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="join-influencer">
          <div className="container">
            <h2 className="influencer-title">
              Join the Elite Circle of Influencers
            </h2>
            <p className="influencer-text">
              Become a part of our exclusive community where luxury,
              convenience, and style are just a tap away. Elevate your influence
              and let the city become your playground.
            </p>
            <div className="influencer-images">
              <div className="row">
                <div className="col-md-3">
                  <div className="influencer-inside-images">
                    <img
                      className="images-influencer"
                      src="/images/Guests/influncr1.webp"
                      alt="First Class Club App For Influencers Experiences In Mykonos"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="influencer-inside-images">
                    <img
                      className="images-influencer"
                      src="/images/Guests/influnce2.webp"
                      alt="First Class Club App For Influencers Experiences In London"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="influencer-inside-images">
                    <img
                      className="images-influencer"
                      src="/images/Guests/influnce3.webp"
                      alt="First Class Club App For Influencers Experiences In Tulum"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="influencer-inside-images">
                    <img
                      className="images-influencer"
                      src="/images/Guests/influnce4.webp"
                      alt="First Class Club App For Influencers Experiences In Losangels"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-Btntop">
              <Link className="contact-usbtn">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guests;
